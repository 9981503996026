.logo {
  display: block;
  height: 64px;
  /* background: url('@assets/img/logo-1.png') no-repeat center; */
  background-size: contain;
  line-height: 64px;
}

.sider {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow: auto;
}

:global(.no-year-calendar .ant-fullcalendar-header .ant-radio-group) {
  display: none;
}

:global(.no-year-calendar .ant-fullcalendar-header .ant-radio-group) {
  display: none;
}
