

.main-content {
    overflow: scroll;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
}

:global(.ant-layout-header) {
    display: flex;
    align-items: center;

    :global(.ant-breadcrumb) {
        :global(.ant-breadcrumb-link) {
            color: rgba(255, 255, 255, 0.45)
        }
        :global(.ant-breadcrumb-separator) {
            color: rgba(255, 255, 255, 0.45)
        }

        li:last-child {
            color: rgba(255, 255, 255, 0.85)
        }

        li:last-child a {
            color: rgba(255, 255, 255, 0.85)
        }
    }
}

:global(td > button.ant-btn-link) {
    padding: 4px 5px;
}

.user-action {
    margin-left: auto;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.85);
}
