@import "/node_modules/antd/dist/antd.less";

.root {
  height: 100%;
  display: flex;
}

.main-content {
  overflow: auto;
  min-height: 0;
  flex: 1 1 0;
}
