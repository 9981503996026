

.map-container {
    position: relative;
     display: unset;
    height: 100%;
    width: 100%;

    .search-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 400px;
    }

    .map .poi-more, .poi-more {
        display: none;
    }
}


.poi-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    height: auto;

    .icon {
        width: 19px;
        height: 31px;
        text-align: center;
        color: white;
        background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png) no-repeat;
    }

    .icon.selected {
        background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png);
    }

    .poi-desc {
        margin-left: 25px;
        flex: 1 1 0;
        min-width: 0;
        display: flex;
        flex-direction: column;

        .poi-title {
            font-size: 13px;
            overflow: hidden;
            font-weight: 400;
        }
    
        .poi-info {
            word-break: break-all;
            overflow: hidden;

            p {
                color: #999;
                font-family: Tahoma;
                word-break: break-word;
                white-space: pre-wrap;
                line-height: 20px;
                margin: 0;
            }
        }
    }

    .poi-image {
        width: 90px;
        height: 56px;
        margin-left: 25px;
        overflow: hidden;
    }

}
