.ant-form.query-form {
    padding: 24px 2%;
    background: #fbfbfb;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    .query-form-item {
        min-width: 25%;
        max-width: 25%;
        flex: 1 1 0;
        padding-left: 2%;

        .ant-input-number {
            width: 100%;
        }
    }

    & > div:nth-child(4n+1) {
        padding-left: 0;
    }

    & > .operation {
        display: flex;
        flex-direction: row;
        margin-left: auto;

        button:not(:first-child) {
            margin-left: 10px;
        }
    }
}

.ant-form.editor-form {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 10px;

    .editor-form-item-2 {
        width: 48%;
        min-width: 48%;
        max-width: 48%;
        margin-right: 2%;
        display: inline-block;


        .ant-input-number {
            width: 100%;
        }
        
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    

    .editor-form-item {
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        .ant-input-number {
            width: 100%;
        }
    }

    & > .operation {
        width: 100%;
        text-align: center;
        button {
            width: 15%;
        }

        button:not(:first-child) {
            margin-left: 10px;
        }
    }
}
