

.hide-year-selector:global(.ant-picker-dropdown) {
    :global(.ant-picker-header) {
        display: none;
    }
    :global(.ant-picker-panels) {

        & > div:nth-child(2) {
            display: none;
        }

        :global(.ant-picker-content) {
            thead {
                display: none;
            }

            :global(td.ant-picker-cell.ant-picker-cell-disabled::before) {
                background-color: transparent;
            }
        }
    }
}
