
:global(.ant-tabs-top) {
    &.card-tabs {

        & > :global(.ant-tabs-nav) {
            margin: 5px 0 -1px 0
        }
    
    }
}
